import "./custom.css";
import "./App.css";
import React, { Component, useState, useEffect } from "react";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";

import axios from "axios";

import { networks } from "./networks";
import abi from "./abi.json";
const contracts = {
  "0x1": "0xC53452d59956A29229d16Cc27393f005D4526083", //eth
  "0x89": "0x12F0215Ee1fd10a3a8F19224265AAC5472961639", //polygon
  "0xa4b1": "0x12F0215Ee1fd10a3a8F19224265AAC5472961639", //arbitrum
  "0xa": "0x12F0215Ee1fd10a3a8F19224265AAC5472961639", //optimism
};

const chains = {
  Ethereum: "0x1",
  Polygon: "0x89",
  Arbitrum: "0xa4b1",
  Optimism: "0xa",
};
const chainNames = {
  "0x1": "Ethereum",
  "0x89": "Polygon",
  "0xa4b1": "Arbitrum",
  "0xa": "Optimism",
};
const chainNamesMetadata = {
  Ethereum: "eth",
  Polygon: "poly",
  Arbitrum: "arb",
  Optimism: "op",
};
const LZchains = {
  Ethereum: 101,
  Polygon: 109,
  Arbitrum: 110,
  Optimism: 111,
};
const LZgas = {
  Ethereum: 0.002 * 10 ** 18.0,
  Polygon: 40 * 10 ** 18.0,
  Arbitrum: 0.02 * 10 ** 18.0,
  Optimism: 0.02 * 10 ** 18.0,
};
const totalCount = {
  Ethereum: 266,
  Polygon: 236,
  Arbitrum: 248,
  Optimism: 250,
};

function App() {
  const [userAddress, setUserAddress] = useState("connect wallet");
  const [currentChain, setCurrentChain] = useState("");
  const [idsfound, setIdsfound] = useState([]);
  const [traits, setTraits] = useState([]);
  const [total, setTotal] = useState(0);
  const [leaderboard, setLeaderboard] = useState(
    <div class="step-form">
      <div class="step-form-data epilogue m-b-30 ">
        <label class="checkbox-label1">
          <input type="checkbox" name="" />
          <i></i>
        </label>
      </div>
    </div>
  );

  useEffect(() => {
    initializeWeb3();
    loadLeaderboard();
  }, []);

  async function loadLeaderboard() {
    await axios
      .get("https://fishbags-nft-default-rtdb.firebaseio.com/metadata/eth.json")
      .then((res) => {
        let m = res.data;
        for (let i = 1; i < 1001; i++) {
          for (let j = i; j < 1001; j++) {
            if (
              Number(m[i].attributes[7].value) <
              Number(m[j].attributes[7].value)
            ) {
              let t = m[i];
              m[i] = m[j];
              m[j] = t;
            }
          }
        }

        let l = [];
        for (let i = 1; i < 6; i++) {
          l.push(
            <div class="step-form">
              <div class="step-form-data-scores epilogue m-b-30 ">
                <label class="checkbox-label1">
                  <div class="row">
                    <div class="column">
                      <b>fishbag </b> {m[i].name} <br />
                      <b>score:</b> {m[i].attributes[7].value}
                    </div>
                  </div>
                </label>
              </div>
            </div>
          );
        }
        setLeaderboard(l);
      });
  }

  async function initializeWeb3() {
    if (await detectEthereumProvider()) {
      window.web3 = new Web3(window.ethereum);
      const web3 = window.web3;
      const chainId = await window.ethereum.request({ method: "eth_chainId" });

      if (!contracts[chainId]) {
        console.log("Unsupported Network");
        return;
      }

      let metaMaskAccount = await web3.eth.getAccounts();
      metaMaskAccount = metaMaskAccount[0];

      const ct = new web3.eth.Contract(abi, contracts[chainId]);
      setCurrentChain(chainNames[chainId]);
      setTotal(await ct.methods.mintcount().call());

      loadIds();
      let e = ["", "", "", "", "", "", "", ""];
      setTraits(e);
      document.getElementById("tokenId").selectedIndex = 0;
    }
  }

  const loadIds = async () => {
    window.web3 = new Web3(window.ethereum);
    const web3 = window.web3;
    const chainId = await window.ethereum.request({ method: "eth_chainId" });

    let metaMaskAccount = await web3.eth.getAccounts();
    metaMaskAccount = metaMaskAccount[0];

    const ct = new web3.eth.Contract(abi, contracts[chainId]);
    const b = await ct.methods.balanceOf(metaMaskAccount).call();

    let ids = [];
    for (let i = 0; i < b; i++) {
      ids.push(await ct.methods.tokenOfOwnerByIndex(metaMaskAccount, i).call());
    }
    setIdsfound(ids);
  };

  async function loadTraits(event) {
    let id = event.target.value;
    let traitsX = [];
    console.log(chainNamesMetadata[currentChain]);
    await axios
      .get(
        "https://fishbags-nft-default-rtdb.firebaseio.com/metadata/" +
          chainNamesMetadata[currentChain] +
          "/" +
          id +
          ".json"
      )
      .then((res) => {
        traitsX.push(res.data.attributes[0].value);
        traitsX.push(res.data.attributes[1].value);
        traitsX.push(res.data.attributes[2].value);
        traitsX.push(res.data.attributes[3].value);
        traitsX.push(res.data.attributes[4].value);
        traitsX.push(res.data.attributes[5].value);
        traitsX.push(res.data.attributes[6].value);
        traitsX.push(res.data.attributes[7].value);
        traitsX.push(res.data.image);
        setTraits(traitsX);
      });
  }

  try {
    window.ethereum.on("chainChanged", (chainId) => {
      initializeWeb3();
    });
  } catch (error) {
    // console.log(error);
  }

  const connectWallet = async () => {
    if (detectEthereumProvider()) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      setUserAddress("connected");
    } else {
      alert("Ethereum supported wallet not found");
    }
  };

  const changeNetwork = async (networkName) => {
    window.web3 = new Web3(window.ethereum);
    const web3 = window.web3;
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      if (networkName != "Ethereum") {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                ...networks[networkName],
              },
            ],
          });
        } catch (error) {
          alert(error.message);
        }
      } else if (networkName == "Ethereum")
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x1" }],
        });
      setCurrentChain(networkName);
    } catch (err) {
      console.log(err);
    }
  };

  const mint = async (chainId, name) => {
    window.web3 = new Web3(window.ethereum);
    const web3 = window.web3;

    if (chainId != (await window.ethereum.request({ method: "eth_chainId" }))) {
      alert("Enable " + name + " Network!");
      return;
    }
    if (!contracts[chainId]) {
      console.log("Unsupported Network");
      return;
    }

    await window.ethereum.enable();

    let metaMaskAccount = await web3.eth.getAccounts();
    metaMaskAccount = metaMaskAccount[0];

    const ct = new web3.eth.Contract(abi, contracts[chainId]);

    try {
      let p = await ct.methods.PRICE().call();
      await ct.methods.mint().send({ from: metaMaskAccount, value: p });
    } catch (error) {
      console.log(error);
    }

    initializeWeb3();
  };

  const traverse = async () => {
    window.web3 = new Web3(window.ethereum);
    const web3 = window.web3;

    await window.ethereum.enable();

    let metaMaskAccount = await web3.eth.getAccounts();
    metaMaskAccount = metaMaskAccount[0];

    const ctx = new web3.eth.Contract(abi, contracts[chains["Ethereum"]]);

    let t = document.getElementById("tokenIdTraverse").value;
    let s = document.getElementById("source").value;
    let d = document.getElementById("dest").value;
    if (s == d) {
      alert("Source and destination cannot be same");
      return;
    }

    await changeNetwork(s);

    const ct = new web3.eth.Contract(abi, contracts[chains[s]]);

    try {
      let a = await ct.methods.ownerOf(t).call();
      if (a != metaMaskAccount) {
        alert("You are not owner of fishbag#" + t);
        return;
      }
    } catch (error) {
      alert("fishbag#" + t + " not found on " + s);
      return;
    }

    try {
      await ct.methods.traverseChains(LZchains[d], t).send({
        from: metaMaskAccount,
        value: String(LZgas[s]),
      });
    } catch (error) {
      console.log(error);
    }

    initializeWeb3();
  };

  return (
    <React.Fragment>
      <div class="container">
        <div class="table">
          <div class="nav-actions2">
            <br />
            <button
              class="custom-btn1"
              onClick={connectWallet}
              type="button"
              disabled={userAddress == "connected"}
            >
              <span> {userAddress} </span>
            </button>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div class="container">
          <img
            src="images/fishbags.gif"
            class="col-white"
            width="60"
            height="60"
          />
          <div class="block-element ">
            <div class="row">
              <div class="col-md-8 col-lg-8 col-sm-12 col-12 sec-wid-left">
                <div class="block-element">
                  <div class="custom-block2 m-b-30">
                    <div class="sec-head">
                      <h1 class="col-white"> fishbags.lol </h1>
                    </div>
                    <div class="textual-block2 inter">
                      <label class="checkbox-label1">
                        <input type="checkbox" name="" /> easy now
                      </label>
                      <label class="checkbox-label1">
                        <input type="checkbox" name="" /> this is mint 2 play
                      </label>
                      <label class="checkbox-label1">
                        <input type="checkbox" name="" /> not play & dump
                      </label>
                    </div>
                  </div>
                  <div class="block-element">
                    <div class="custom-block2 m-b-30">
                      <div class="sec-head">
                        <h1 class="col-white"> mint & play </h1>
                      </div>
                      <div class="wrapper-2">
                        <div class="block-element m-b-10">
                          <div class="step-form">
                            <div class="step-form-data epilogue m-b-10">
                              <label class="checkbox-label1">
                                <input type="checkbox" name="" /> to start
                                you'll need to add and enable your chain of
                                choice... you can do that here on{" "}
                                <b>fishbags.lol</b> w/ our highly advanced{" "}
                                <b>
                                  ADDCHAIN<sup>gm</sup>
                                </b>{" "}
                                technology.
                              </label>
                            </div>

                            <form>
                              <div class="block-element buttons-holder5 text-center m-b-10">
                                {" "}
                                add and enable using buttons below: <br />
                                <button
                                  class="custom-btn2"
                                  onClick={() => changeNetwork("Ethereum")}
                                  type="button"
                                  disabled={currentChain == "Ethereum"}
                                >
                                  {" "}
                                  <span>eth</span>
                                </button>
                                <button
                                  class="custom-btn2"
                                  onClick={() => changeNetwork("Polygon")}
                                  type="button"
                                  disabled={currentChain == "Polygon"}
                                >
                                  {" "}
                                  <span>poly</span>
                                </button>
                                <button
                                  class="custom-btn2"
                                  onClick={() => changeNetwork("Arbitrum")}
                                  type="button"
                                  disabled={currentChain == "Arbitrum"}
                                >
                                  {" "}
                                  <span>arb</span>
                                </button>
                                <button
                                  class="custom-btn2"
                                  onClick={() => changeNetwork("Optimism")}
                                  type="button"
                                  disabled={currentChain == "Optimism"}
                                >
                                  {" "}
                                  <span>op</span>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="wrapper-2">

                        <div class="block-element buttons-holder5 text-center">
                          <a href="https://play.fishbags.lol">
                            <button
                              class="custom-btn6 m-b-30"
                            >
                              <span>
                                <h2> play </h2>
                              </span>
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="block-element">
                  <div class="block-element">
                    <div class="custom-block2 m-b-30">
                      <div class="sec-head">
                        <h1 class="col-white">
                          your fishbags on {currentChain.toUpperCase()}
                        </h1>
                      </div>
                      <div class="content-block1">
                        <img src={traits[8]} className="w-100" />
                        <br />
                        <h4 class="col-black2 d-inline">
                          {" "}
                          <b>fishbags id: </b>
                        </h4>
                        <select
                          className="field-style1"
                          id="tokenId"
                          onChange={(event) => loadTraits(event)}
                        >
                          <option selected="true">select</option>
                          {idsfound.map(function (id, i) {
                            return <option value={id}>{id}</option>;
                          })}
                        </select>
                        <h4 class="col-black1 d-inline">
                          {" "}
                          <b>bagged on:</b>{" "}
                        </h4>

                        <input
                          placeholder=""
                          type="text"
                          class="field-style1"
                          disabled
                          id="species"
                          value={traits[0]}
                        />
                        <h4 class="col-black1 d-inline">
                          {" "}
                          <b>liquid:</b>{" "}
                        </h4>
                        <input
                          placeholder=""
                          type="text"
                          class="field-style1"
                          disabled
                          id="species"
                          value={traits[1]}
                        />
                        <h4 class="col-black1 d-inline">
                          {" "}
                          <b>species:</b>
                        </h4>
                        <input
                          placeholder=""
                          type="text"
                          class="field-style1"
                          id=""
                          disabled
                          value={traits[2]}
                        />
                        <h4 class="col-black1 d-inline">
                          {" "}
                          <b>color:</b>{" "}
                        </h4>
                        <input
                          placeholder=""
                          type="text"
                          class="field-style1"
                          value={traits[3]}
                          disabled
                        />
                        <h4 class="col-black1 d-inline">
                          {" "}
                          <b>expression:</b>{" "}
                        </h4>
                        <input
                          placeholder=""
                          type="text"
                          class="field-style1"
                          value={traits[4]}
                          disabled
                        />
                        <h4 class="col-black1 d-inline">
                          {" "}
                          <b> accessory:</b>{" "}
                        </h4>
                        <input
                          placeholder=""
                          type="text"
                          class="field-style1"
                          value={traits[5]}
                          disabled
                        />
                        <h4 class="col-black1 d-inline">
                          {" "}
                          <b> swimming on:</b>{" "}
                        </h4>
                        <input
                          placeholder=""
                          type="text"
                          class="field-style1"
                          value={traits[6]}
                          disabled
                        />
                        <h4 class="col-black1 d-inline">
                          {" "}
                          <b>score:</b>{" "}
                        </h4>
                        <input
                          placeholder=""
                          type="text"
                          class="field-style1"
                          value={traits[7]}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="custom-block2 m-b-30">
                  <div class="sec-head m-b-20">
                    <h1 class="col-white"> disclaimer</h1>
                  </div>
                  <div class="step-form">
                    <div class="step-form-data epilogue m-b-30 ">
                      <label class="checkbox-label1">
                        <input type="checkbox" name="" />{" "}
                        <i>
                          {" "}
                          this is an experimental project. minting fishbags NFT
                          and/or purchasing them on secondary markets entitles
                          you to nothing from the project or the developers.{" "}
                        </i>
                      </label>
                      <label class="checkbox-label1">
                        <input type="checkbox" name="" />{" "}
                        <i> all media associated with fishbags is cc0.</i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-lg-8 col-sm-12 col-12 sec-wid-right">
                <div class="block-element">
                  <div class="custom-block2 m-b-30">
                    <div class="sec-head m-b-10">
                      <h1 class="col-white">
                        {" "}
                        travLite<sup>gm</sup>{" "}
                      </h1>
                    </div>
                    <div class="step-form">
                      <div class="step-form-data epilogue">
                        <label class="checkbox-label1">
                          <input type="checkbox" name="" />
                          <b> fishbags</b> is built on{" "}
                          <a href="https://layerzero.network/pdf/LayerZero_Whitepaper_Release.pdf">
                            LayerZero
                          </a>{" "}
                          tech, this allows you to send your bag to any of the 4
                          chains we support.
                        </label>
                        <label class="checkbox-label1">
                          <input type="checkbox" name="" /> you can traverse the
                          chains right here using our highly advanced{" "}
                          <b>
                            travLite<sup>gm</sup>
                          </b>{" "}
                          tool.
                        </label>
                        <label class="checkbox-label1">
                          <input type="checkbox" name="" /> the cost of
                          traversal from the source chain to any other chain,
                          <br />
                          <b>ethereum: .002 eth </b>
                          <br />
                          <b>arbitrum: .02 eth</b>
                          <br />
                          <b>optimism: .02 eth </b>
                          <br />
                          <b>polygon: 40 MATIC </b>
                          <br />
                          <input type="checkbox" name="" /> this covers gas fees
                          on source and destination chains, any excess is
                          returned to sender in the same transaction.{" "}
                        </label>
                      </div>
                    </div>
                    <div class="textual-block1 inter m-0">
                      <div class="row">
                        <div class="col-md-4 col-lg-4">
                          {" "}
                          <h4 class="col-black1 d-inline"> fishbags id: </h4>
                          <select className="field-style1" id="tokenIdTraverse">
                            {idsfound.map(function (id, i) {
                              return <option value={id}>{id}</option>;
                            })}
                          </select>
                        </div>

                        <div class="col-md-4 col-lg-4 col-sm-6 col-12">
                          <h4 class="col-black1 d-inline"> source chain: </h4>
                          <select id="source" class="field-style1">
                            <option value="Ethereum">eth</option>
                            <option value="Polygon">poly</option>
                            <option value="Arbitrum">arb</option>
                            <option value="Optimism">op</option>
                          </select>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-6 col-12">
                          <h4 class="col-black1 d-inline"> dest chain: </h4>
                          <select id="dest" class="field-style1">
                            <option value="Ethereum">eth</option>
                            <option value="Polygon">poly</option>
                            <option value="Arbitrum">arb</option>
                            <option value="Optimism">op</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="block-element buttons-holder5 text-center m-b-30">
                      <button class="custom-btn3" onClick={traverse}>
                        <span>
                          <h2>traverse</h2>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="custom-block2 m-b-30">
                    <div class="sec-head m-b-20">
                      <h1 class="col-white"> top 5 fishbags</h1>
                    </div>
                    {leaderboard}
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default App;
